import { createAction, props } from '@ngrx/store';
import { BasicResponse } from 'common';
import { CommonItem, MessageType, MetaData, PhoneNumber, SearchFiltersData, SearchParameters, SearchResults } from './interfaces';

export enum SmsBuilderInnerActionTypes {
    GetMetaDataSuccess = '[SmsBuilder] Get Meta Data Success',
    GetMetaDataError = '[SmsBuilder] Get Meta Data Error',
    SearchAllMessagesSuccess = '[SmsBuilder] Search All Messages Success',
    SearchAllMessagesError = '[SmsBuilder] Search All Messages Error',
    GetMessageTypesListSuccess = '[SmsBuilder] Get Message Types List Success',
    GetMessageTypesListError = '[SmsBuilder] Get Message Types List Error',
    GetStatusOptionsListSuccess = '[SmsBuilder] Get Status Options List Success',
    GetStatusOptionsListError = '[SmsBuilder] Get Status Options List Error',
    GetSendingPhoneNumbersListSuccess = '[SmsBuilder] Get Sending Phone Numbers List Success',
    GetSendingPhoneNumbersListError = '[SmsBuilder] Get Sending Phone Numbers List Error',
    GetReportUrlSuccess = '[SmsBuilder] Get Report Url Success',
    GetReportUrlError = '[SmsBuilder] Get Report Url Error',
}

export enum SmsBuilderHttpActionTypes {
    GetMetaData = '[SmsBuilder] Get Meta Data',
    SearchAllMessages = '[SmsBuilder] Search All Messages',
    GetMessageTypesList = '[SmsBuilder] Get Message Types List',
    GetStatusOptionsList = '[SmsBuilder] Get Status Options List',
    GetSendingPhoneNumbersList = '[SmsBuilder] Get Sending Phone Numbers List',
    GetReportUrl = '[SmsBuilder] Get Report Url',
}

//HTTP
export const GetMetaData = createAction(SmsBuilderHttpActionTypes.GetMetaData);
export const SearchAllMessages = createAction(SmsBuilderHttpActionTypes.SearchAllMessages, props<{ payload: { environmentId?: number, queryParams: SearchParameters, filterData?: SearchFiltersData } }>());
export const GetMessageTypesList = createAction(SmsBuilderHttpActionTypes.GetMessageTypesList, props<{ payload: { isCreateEdit?: boolean } }>());
export const GetStatusOptionsList = createAction(SmsBuilderHttpActionTypes.GetStatusOptionsList);
export const GetSendingPhoneNumbersList = createAction(SmsBuilderHttpActionTypes.GetSendingPhoneNumbersList);
export const GetReportUrl = createAction(SmsBuilderHttpActionTypes.GetReportUrl, props<{ payload: { messageId: number } }>());

// INNER
export const GetMetaDataSuccess = createAction(SmsBuilderInnerActionTypes.GetMetaDataSuccess, props<{ payload: MetaData }>());
export const GetMetaDataError = createAction(SmsBuilderInnerActionTypes.GetMetaDataError, props<{ payload: BasicResponse }>());
export const SearchAllMessagesSuccess = createAction(SmsBuilderInnerActionTypes.SearchAllMessagesSuccess, props<{ payload: SearchResults }>());
export const SearchAllMessagesError = createAction(SmsBuilderInnerActionTypes.SearchAllMessagesError, props<{ payload: BasicResponse }>());
export const GetMessageTypesListSuccess = createAction(SmsBuilderInnerActionTypes.GetMessageTypesListSuccess, props<{ payload: Array<MessageType> }>());
export const GetMessageTypesListError = createAction(SmsBuilderInnerActionTypes.GetMessageTypesListError, props<{ payload: BasicResponse }>());
export const GetStatusOptionsListSuccess = createAction(SmsBuilderInnerActionTypes.GetStatusOptionsListSuccess, props<{ payload: Array<CommonItem> }>());
export const GetStatusOptionsListError = createAction(SmsBuilderInnerActionTypes.GetStatusOptionsListError, props<{ payload: BasicResponse }>());
export const GetSendingPhoneNumbersListSuccess = createAction(SmsBuilderInnerActionTypes.GetSendingPhoneNumbersListSuccess, props<{ payload: Array<PhoneNumber> }>());
export const GetSendingPhoneNumbersListError = createAction(SmsBuilderInnerActionTypes.GetSendingPhoneNumbersListError, props<{ payload: BasicResponse }>());
export const GetReportUrlSuccess = createAction(SmsBuilderInnerActionTypes.GetReportUrlSuccess, props<{ payload: { description: string, value: string } }>());
export const GetReportUrlError = createAction(SmsBuilderInnerActionTypes.GetReportUrlError, props<{ payload: BasicResponse }>());